<template>
  <b-row>
    <b-col md="12">
      <b-card
        title="Expertises traitées"
        no-body
      >
        <b-card-body>
          <b-row>
            <b-col md="12">
              <b-card
                title="Expertises traitées"
                no-body
              >
                Expertises traitées
              </b-card>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                class="float-right mb-1 ml-1"
                onclick="history.back()"
              >
                <feather-icon icon="ArrowLeftCircleIcon" />
                Retour
              </b-button>
            </b-col>
          </b-row>
          <div class="d-flex justify-content-between flex-wrap">
            <!-- sorting  -->
            <b-form-group
              label="Trier"
              label-size="sm"
              label-align-sm="left"
              label-cols-sm="2"
              label-for="sortBySelect"
              class="mb-md-0"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                >
                  <template #first>
                    <option value="">
                      aucun
                    </option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  :disabled="!sortBy"
                >
                  <option :value="false">
                    Croissant
                  </option>
                  <option :value="true">
                    Décroissant
                  </option>
                </b-form-select>
              </b-input-group>
            </b-form-group>

            <!-- filter -->
            <b-form-group
              label="Filtrer"
              label-cols-sm="2"
              label-align-sm="left"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Rechercher"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Effacer
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
        </b-card-body>

        <b-table
          striped
          hover
          responsive
          small
          :busy="loader"
          :empty-filtered-text="'Aucun résultat n\'a trouvé'"
          :empty-text="'Aucun résultat n\'a trouvé'"
          show-empty
          class="position-relative"
          :per-page="perPage"
          :current-page="currentPage"
          :items="expertises"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #table-busy>
            <div class="d-flex justify-content-center mb-3">
              <b-spinner
                style="width: 2rem; height: 2rem"
                variant="primary"
                label="Large Spinner"
                type="grow"
                class="mt-2"
              />
              <span class="sr-only">Chargement en cours...</span>
            </div>
          </template>
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template v-slot:cell(plaignant)="data">
            {{ data.item.user.lastname }} {{ data.item.user.firstname }}
          </template>
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              class="float-right mb-1"
              :to="{ name: 'espace-societe.detail-expertise', params: { id: data.item.id } }"
            >
              Voir le détail
              <feather-icon icon="ArrowRightCircleIcon" />

            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              size="sm"
              class="float-right mb-1 mr-2"
              @click="changerStatusExpertise(data.item,'En Cours')"
            >
              Relancer
              <feather-icon icon="ReloadIcon" />
            </b-button>
          </template>
        </b-table>

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- page length -->
          <b-form-group
            label="Par Page"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="expertises.length"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0 pagination-center"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { ref, onMounted, reactive } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BRow,
  BCol,
  BCard,
  BTable,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BInputGroupAppend,
  BButton,
  BCardBody,
  BFormInput,
  BSpinner,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
// eslint-disable-next-line import/no-cycle
import useExpertises from '@/services/expertise/expertiseservice'
import { $themeConfig } from '@themeConfig'
// eslint-disable-next-line import/no-extraneous-dependencies
import Pusher from 'pusher-js'
// eslint-disable-next-line import/no-cycle
import useAuthUser from '@/services/authentification/userService'
// eslint-disable-next-line import/no-cycle
import router from '@/router'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BSpinner,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  setup() {
    const {
      loader, commentaires, Success, changeStatusExpertise, getAllCommentByDevis, saveComment, getAllExpertises, expertises, deleteExpertiseById,
    } = useExpertises()
    const {
      getAuthUser, user,
    } = useAuthUser()
    const perPage = 10
    const pageOptions = [3, 5, 10]
    const totalRows = ref(1)
    const currentPage = ref(1)
    const sortBy = ''
    const sortDesc = false
    const sortDirection = 'asc'
    const filter = null
    const filterOn = []
    const infoModal = {
      id: 'info-modal',
      title: '',
      content: '',
    }
    const deleteExpertise = async id => {
      await deleteExpertiseById(id)
    }
    const fields = [
      { key: 'index', label: 'N°' },
      { key: 'reference', label: 'REFERENCE', sortable: true },
      { key: 'type_expertise.libelle', label: 'TYPE D\'EXPERTISE', sortable: true },
      { key: 'question', label: 'OBJET', sortable: true },
      { key: 'created_at', label: 'DTE DE PLAINTE', sortable: true },
      { key: 'actions' },
    ]
    const stateDevis = reactive({ etat: '' })
    const etat = ref('')

    // eslint - disable global-require /
    // const items = devis.value
    const devisCode = ref('')

    const getDevisCode = (code, stateValidity) => {
      devisCode.value = code
      stateDevis.etat = stateValidity
    }
    const form = reactive({
      devis_id: null,
      message: '',
    })
    const changerStatusExpertise = async (item, status) => {
      await changeStatusExpertise(item.id, { statut: status, expert_id: JSON.parse(store.state.authStore.user).id, expertise_id: item.id })
      if (Success.value) {
        // createCommentaire({
        //   expertise_id: item.id,
        //   reponse_a: null,
        //   expert_id: JSON.parse(store.state.authStore.user).id,
        //   description: `Bonjour, comment allez-vous? je vous écris pour vous porter assistance dans votre demande d'expertise ${item.reference}`,
        // })
        router.push({ name: 'espace-societe.expertises-soumises', params: { id: item.id } })
      }
    }
    onMounted(async () => {
      await getAuthUser()
      await getAllExpertises('Fermée')
    })
    const { PUSHER_APP_CLUSTER, PUSHER_APP_KEY } = $themeConfig.app
    const pusher = new Pusher(PUSHER_APP_KEY, {
      cluster: PUSHER_APP_CLUSTER,
    })

    const channel = pusher.subscribe('commentaire-devis-channel')

    channel.bind('commentaire-devis-event', async () => {
      if (form.devis_id !== null) {
        await getAllCommentByDevis(form.devis_id)
      }
    })
    const getCommentByDevis = async id => {
      form.devis_id = id
      await getAllCommentByDevis(id)
    }
    const saveCommentaire = async () => {
      await saveComment({ ...form })
      form.message = null
    }

    const onFiltered = filteredItems => {
      // Trigger pagination to update the number of buttons/pages due to filtering
      totalRows.value = filteredItems.length
      currentPage.value = 1
    }

    const formatter = value => value.toUpperCase()
    return {
      perPage,
      pageOptions,
      totalRows,
      currentPage,
      sortBy,
      sortDesc,
      sortDirection,
      filter,
      filterOn,
      infoModal,
      fields,
      deleteExpertiseById,
      onFiltered,
      formatter,
      Success,
      changeStatusExpertise,
      loader,
      form,
      user,
      devisCode,
      deleteExpertise,
      getDevisCode,
      stateDevis,
      etat,
      changerStatusExpertise,
      getCommentByDevis,
      commentaires,
      saveCommentaire,
      expertises,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  methods: {
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmitModal()
    },
    handleSubmitModal() {
      // Exit when the form isn't valid
      this.suspendreDevis(this.devisCode, this.stateDevis)
      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs['my-modal'].toggle('#toggle-btn')
      })
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style>
@import '../../../../../assets/css/chat.css';
</style>
